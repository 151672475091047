<template>
  <div class="no-scroll-content flex flex-col border border-solid d-theme-border-grey-light">
    <!--tabs-->
    <div class="flex-none border-t-0 border-b border-l-0 border-r-0 border-solid d-theme-border-grey-light">
      <div class="p-2 vx-card shadow-none rounded-none">
        <div class="flex h-auto">
          <ul class="flex items-center text-xs overflow-x-auto">
            <li v-for="(tab, index) in tabs.items"
                :key="index"
                :class="{'text-primary font-bold bg-primary-transparent-25': tabs.active === tab.code, 'hover-bg-primary-transparent-25': tabs.active !== tab.code}"
                @click="tabs.active = tab.code"
                class="cursor-pointer px-2 py-1 select-none whitespace-no-wrap border-solid d-theme-border-grey-light border-b-0 border-t-0 border-l-0 border-r">
              {{ tab.title }}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!--content-->
    <div class="flex-grow">
      <div class="flex h-full">
        <div class="w-full sm:w-12/12 d-theme-dark-bg border-solid d-theme-border-grey-light border border-t-0 border-b border-r-0 border-l-0">
          <TabGeneralLedger v-show="tabs.active === 'GENERAL_LEDGER'" :is-active="tabs.active === 'GENERAL_LEDGER'"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moduleGeneralLedger from '@/store/modules/accounting/general-ledger/general-ledger.store'
import TabGeneralLedger from '@/views/pages/accounting/general-ledger/tabs/TabGeneralLedger'

export default {
  name: 'GeneralLedger',
  components: {
    TabGeneralLedger
  },
  data () {
    return {
      tabs: {
        active: 'GENERAL_LEDGER',
        items: [
          { code: 'GENERAL_LEDGER', title: 'General Ledger' }
        ]
      }
    }
  },
  beforeCreate () {
    this.$store.registerModule(['accounting', 'general-ledger'], moduleGeneralLedger)
  },
  beforeDestroy () {
    this.$store.commit('accounting/general-ledger/RESET_STATE')
    this.$store.unregisterModule(['accounting', 'general-ledger'])
  }
}
</script>

<style>
.tableSticky {
  overflow: auto;
  height: calc(100vh - 320px);
}

.tableSticky thead tr {
  position: sticky;
  top: -1px;
  z-index: 1;
}

.tableSticky tfoot tr {
  position: sticky;
  bottom: -1px;
  z-index: 1;
}
</style>
