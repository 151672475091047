<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <!------------ header ------------->
      <div class="flex-none border-t-0 border-b border-l-0 border-r-0 border-solid d-theme-border-grey-light">
        <div class="flex flex-wrap space-x-3 px-2 py-1 vx-card shadow-none">
          <div class="flex flex-row w-full space-x-3 flex-wrap">
            <div>
              <div class="flex items-center text-xs">
                <label class="mr-1 text-xs whitespace-no-wrap">COA</label>
                <vs-input class="w-full text-xs" v-model="filter.coa" type="text" @click="showModalCoa()" readonly/>
              </div>
            </div>
            <div>
              <div class="flex items-center text-xs">
                <label class="mr-1 text-xs whitespace-no-wrap">Proyek</label>
                <vs-select class="vs-select-small" :value="null" v-model="filter.id_proyek">
                  <vs-select-item class="vs-select-small" text="Semua"/>
                  <vs-select-item class="vs-select-small" v-for="(it, index) in proyeks" :key="index" :value="it.id" :text="`${it.kode} - ${it.nama}`"/>
                </vs-select>
              </div>
            </div>
            <div>
              <div class="flex items-center">
                <label class="mr-1 text-xs whitespace-no-wrap">Periode</label>
                <vx-input-group>
                  <template slot="prepend">
                    <vx-input-group>
                      <flat-pickr class="w-full flatpickr-input rounded-none text-xs" v-model="filter.from_date"></flat-pickr>
                    </vx-input-group>
                    <div class="text-xs px-2 flex items-center border border-r-0 border-l-0 border-solid rounded-none d-theme-border-grey-light" style="background-color: rgba(121,121,121,0.1); border-color: rgba(71,71,71,0.3)">
                      <span class="p-0">s/d</span>
                    </div>
                    <vx-input-group>
                      <flat-pickr class="w-full flatpickr-input rounded-none text-xs" v-model="filter.to_date"></flat-pickr>
                    </vx-input-group>
                  </template>
                </vx-input-group>
              </div>
            </div>
            <div>
              <vs-button class="px-10 py-3 text-xs text-center" color="primary" :disabled="loading.getData" @click="getData">
                {{ loading.getData ? 'Loading...' : 'Filter' }}
              </vs-button>
            </div>
          </div>
        </div>
        <div class="flex justify-between border-t border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light" style="background-color: rgba(121,121,121,0.1);">
          <div class="w-full h-8 flex justify-between" style="background-color: rgba(121,121,121,0.1);">
            <div class="flex items-center justify-start px-1">
              <p class="text-xs font-medium p-2 text-dark text-bold">Data Result</p>
              <QueryEditor v-if="$can('view_query')" code="GENERAL_LEDGER"/>
            </div>
          </div>
        </div>
      </div>

      <!----------- content ------------->
      <div class="flex-1 border-t-0 border-b border-l-0 border-r-0 border-solid d-theme-border-grey-light">
        <div class="box-content-height overflow-auto">
            <table class="w-full table-fixed table-sticky border-collapse">
              <!-----------TABLE HEAD------------>
              <thead class="font-extrabold border border-solid d-theme-border-grey-light">
                <tr class="d-theme-dark-bg">
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-32">No. Jurnal</th>
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-26">Tgl. Jurnal</th>
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-24">Proyek</th>
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-full">Keterangan</th>
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-32">Debet</th>
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-32">Kredit</th>
                  <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-32">Saldo</th>
                </tr>
              </thead>
              <!-----------TABLE BODY------------>
              <tbody v-for="group in data.groups" :key="group.uuid">
                <!--group-->
                <tr class="cursor-pointer hover-bg-primary-transparent-25" @click="group.expanded = !group.expanded">
                  <td class="border border-solid d-theme-border-grey-light p-2 font-medium" colspan="15">
                    <div class="flex items-center">
                      <feather-icon :icon="group.expanded ? 'MinusSquareIcon' : 'PlusSquareIcon'" svg-classes="h-5 w-5" class="mr-2"/>
                      <span class="font-bold text-sm">{{ group.kode_coa }} - {{ group.nama_coa }}</span>
                    </div>
                  </td>
                </tr>
                <template v-if="group.expanded">
                  <tr v-for="(item) in group.items" :key="item.uuid" :class="{'bg-danger-transparent-25': item.id_pv}" @dblclick="onItemClicked($event, item)">
                    <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.no_jurnal }}</td>
                    <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.tgl_jurnal }}</td>
                    <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.kode_proyek }}</td>
                    <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.keterangan_jurnal_d }}</td>
                    <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.debet | idr }}</td>
                    <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.kredit | idr }}</td>
                    <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.saldo | idr }}</td>
                  </tr>

                  <tr class="d-theme-dark-bg border border-solid d-theme-border-grey-light">
                    <th colspan="4" class="border border-solid d-theme-border-grey-light p-2 text-center text-xs">SUBTOTAL</th>
                    <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right">{{ group.totalDebet | idr }}</th>
                    <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right">{{ group.totalKredit | idr }}</th>
                    <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right">{{ group.totalSaldo | idr }}</th>
                  </tr>
                </template>
              </tbody>
              <!-----------TABLE FOOT------------>
              <tfoot>
                <tr class="d-theme-dark-bg border border-solid d-theme-border-grey-light">
                  <th colspan="7" class="border border-solid d-theme-border-grey-light p-2 whitespace-no-wrap text-center text-xs"></th>
                </tr>
              </tfoot>
            </table>
        </div>
      </div>

      <!------------ footer ------------->
      <div class="flex-none p-2 rounded-none vx-card shadow-none rounded-none"></div>
    </div>

    <!--modals-->
    <vs-popup class="sm:popup-w-70 popup-content-no-padding"
              title="Pilih COA"
              :active="modalCoa.active"
              v-on:update:active="modalCoa.active = $event">
      <Coa :selectable="true" :externalFilter="filterCoa" multi-select :isClearCheckedAfterEmit="false" @selected="onSelectedCoa"/>
    </vs-popup>
  </div>
</template>

<script>
import ProyekRepository from '@/repositories/master/proyek-repository'
import GeneralLedgerRepository from '@/repositories/accounting/general-ledger-repository'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'

export default {
  name: 'TabApList',
  props: ['isActive'],
  components: {
    Coa: () => import('@/views/pages/master/coa/Coa'),
    QueryEditor: () => import('@/views/components/query-editor/QueryEditor'),
    flatPickr
  },
  watch: {
    isActive: {
      immediate: true,
      handler (val) {
        if (val && !this.isDataInited) this.initData()
      }
    }
  },
  computed: {
    checkedTransaction () {
      return _.filter(this.data.items, item => item.checked)
    },
    footer () {
      return {
        grandTotalDebet: _.sumBy(this.data.groups, group => _.sumBy(group.items, 'debet')),
        grandTotalKredit: _.sumBy(this.data.groups, group => _.sumBy(group.items, 'kredit')),
        grandTotalSaldo: _.sumBy(this.data.groups, group => _.sumBy(group.items, 'saldo'))
      }
    },
    filterCoa () {
      return {
        tipe: 'DETAIL'
      }
    }
  },
  data () {
    return {
      proyeks: [],
      isDataInited: false,
      modalCoa: {
        active: false
      },
      loading: {
        getData: false
      },
      filter: {
        id_proyek: null,
        list_id_coa: [],
        from_date: moment().startOf('year').format('YYYY-MM-DD'),
        to_date: moment().endOf('month').format('YYYY-MM-DD')
      },
      data: {
        selected: {},
        checkAll: false,
        items: [],
        groups: []
      }
    }
  },
  methods: {
    initData () {
      this.getProyek()
      this.isDataInited = true
    },

    getProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getData () {
      this.loading.getData = true

      const params = {
        id_proyek: this.filter.id_proyek,
        list_id_coa: this.filter.list_id_coa,
        from_date: this.filter.from_date,
        to_date: this.filter.to_date
      }

      GeneralLedgerRepository.get(params)
        .then(response => {
          // this.data.items = response.data.data
          this.data.groups = this.buildGroups(_.cloneDeep(response.data.data))
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading.getData = false
        })
    },

    buildGroups (data) {
      const generateItems = (groupName) => {
        let saldo = 0
        return _.map(_.filter(data, item => item.kode_coa === groupName), item => {
          if (item.group_coa === 'ASSETS' || item.group_coa === 'EXPENSES' || item.group_coa === 'OTHER EXPENSE') {
            saldo += (item.debet - item.kredit)
          } else {
            saldo += (item.kredit - item.debet)
          }
          item.saldo = saldo
          return item
        })
      }

      const generateGroups = () => {
        return _.map(_.uniqBy(data, item => item.kode_coa), item => {
          const items = generateItems(item.kode_coa)
          return {
            uuid: uuid(),
            kode_coa: item.kode_coa,
            nama_coa: item.nama_coa,
            expanded: false,
            items: items,
            totalDebet: _.sumBy(items, item => item.debet),
            totalKredit: _.sumBy(items, item => item.kredit),
            totalSaldo: items[items.length - 1].saldo
          }
        })
      }

      return generateGroups()
    },

    onSelectedCoa (items) {
      const coaIds = _.map(items, item => item.id)
      // const params = { coaIds: coaIds }
      this.filter.coa = coaIds.length + ' COA'
      this.filter.list_id_coa = coaIds
      this.modalCoa.active = false
      // console.log(params)
      console.log(this.filter.list_id_coa)
    },

    showModalCoa () {
      this.modalCoa.active = true
    },

    toggleCheckAll (isChecked) {
      this.data.items = _.map(this.data.items, item => {
        item.checked = isChecked
        return item
      })
    }
  }
}
</script>

<style scoped>
/*navbar floating*/
.navbar-floating .box-content-height {
  height: calc(100vh - 314px);
}
.navbar-floating.footer-sticky .box-content-height {
  height: calc(100vh - 337px);
}
.navbar-floating.footer-hidden .box-content-height {
  height: calc(100vh - 283px);
}

/*navbar sticky*/
.navbar-sticky .box-content-height {
  height: calc(100vh - 300px);
}
.navbar-sticky.footer-sticky .box-content-height {
  height: calc(100vh - 323px);
}
.navbar-sticky.footer-hidden .box-content-height {
  height: calc(100vh - 269px);
}

/*navbar static*/
.navbar-static .box-content-height {
  height: calc(100vh - 282px);
}
.navbar-static.footer-sticky .box-content-height {
  height: calc(100vh - 305px);
}
.navbar-static.footer-hidden .box-content-height {
  height: calc(100vh - 251px);
}

/*navbar hidden*/
.navbar-hidden .box-content-height {
  height: calc(100vh - 237px);
}
.navbar-hidden.footer-sticky .box-content-height {
  height: calc(100vh - 260px);
}
.navbar-hidden.footer-hidden .box-content-height {
  height: calc(100vh - 206px);
}

/*table sticky*/
.table-sticky thead {
  position: sticky;
  top: -1px;
  z-index: 1;
}
.table-sticky tfoot {
  position: sticky;
  bottom: -1px;
  z-index: 1;
}
</style>
