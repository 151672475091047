const getDefaultState = () => {
  return {
    // tab general ledger
    tabGeneralLedger: {}
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    SET_TAB_GENERAL_LEDGER (state, payload) {
      state.tabGeneralLedger = payload
    },
    RESET_STATE (state) {
      Object.assign(state, getDefaultState())
    }
  },

  actions: {}
}
